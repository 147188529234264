export const imports = {
  'src/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-index" */ 'src/index.mdx'
    ),
  'src/components/Article/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-article-index" */ 'src/components/Article/index.mdx'
    ),
  'src/components/ArticleCard/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-article-card-index" */ 'src/components/ArticleCard/index.mdx'
    ),
  'src/components/ArticleGrid/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-article-grid-index" */ 'src/components/ArticleGrid/index.mdx'
    ),
  'src/components/AuthorCard/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-author-card-index" */ 'src/components/AuthorCard/index.mdx'
    ),
  'src/components/Byline/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-byline-index" */ 'src/components/Byline/index.mdx'
    ),
  'src/components/ClassifiedsCard/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-classifieds-card-index" */ 'src/components/ClassifiedsCard/index.mdx'
    ),
  'src/components/CoverPhoto/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-cover-photo-index" */ 'src/components/CoverPhoto/index.mdx'
    ),
  'src/components/Footer/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-footer-index" */ 'src/components/Footer/index.mdx'
    ),
  'src/components/Head/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-head-index" */ 'src/components/Head/index.mdx'
    ),
  'src/components/Header/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-header-index" */ 'src/components/Header/index.mdx'
    ),
  'src/components/Headline/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-headline-index" */ 'src/components/Headline/index.mdx'
    ),
  'src/components/Image/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-image-index" */ 'src/components/Image/index.mdx'
    ),
  'src/components/MainSiteArticleBody/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-main-site-article-body-index" */ 'src/components/MainSiteArticleBody/index.mdx'
    ),
  'src/components/MainSiteArticleHeader/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-main-site-article-header-index" */ 'src/components/MainSiteArticleHeader/index.mdx'
    ),
  'src/components/MainSiteFooter/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-main-site-footer-index" */ 'src/components/MainSiteFooter/index.mdx'
    ),
  'src/components/MainSiteHeader/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-main-site-header-index" */ 'src/components/MainSiteHeader/index.mdx'
    ),
  'src/components/MainSiteImage/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-main-site-image-index" */ 'src/components/MainSiteImage/index.mdx'
    ),
  'src/components/MainSiteLink/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-main-site-link-index" */ 'src/components/MainSiteLink/index.mdx'
    ),
  'src/components/MultimediaScroller/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-multimedia-scroller-index" */ 'src/components/MultimediaScroller/index.mdx'
    ),
  'src/components/Poll/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-poll-index" */ 'src/components/Poll/index.mdx'
    ),
  'src/components/PullQuote/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-pull-quote-index" */ 'src/components/PullQuote/index.mdx'
    ),
  'src/components/ReviewInfobox/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-review-infobox-index" */ 'src/components/ReviewInfobox/index.mdx'
    ),
  'src/components/SectionHeader/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-section-header-index" */ 'src/components/SectionHeader/index.mdx'
    ),
  'src/components/SportsScoreboard/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-sports-scoreboard-index" */ 'src/components/SportsScoreboard/index.mdx'
    ),
  'src/components/SportsTournamentScoreboard/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-sports-tournament-scoreboard-index" */ 'src/components/SportsTournamentScoreboard/index.mdx'
    ),
  'src/components/StoryList/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-story-list-index" */ 'src/components/StoryList/index.mdx'
    ),
  'src/components/Video/index.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-components-video-index" */ 'src/components/Video/index.mdx'
    ),
}
